import axios from 'axios'

export class LoginInfo {
    static async Logar (info, senha) {
        try {
            sessionStorage.removeItem("token");
            let res =  await axios.post('/auth', {
                login: info,
                password: senha,
            });
            sessionStorage.setItem("token", res.data.token);
            return 1;
        }
        catch(e) {
            return 0;
        }
    }
    static async meuLogin () {
        return axios.post('/auth/me')
    }
    static async logout () {
        return axios.get('/auth/logout')
    }

    static async WhoIam (token){
        try {
          return axios.get('/whoiam',{
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+token,
            //cache
            headers: {
              'cache-control': 'max-age=3600',
            }
          });

        } catch (e) {
          return e;
        }
    }

    static async trocarSenhaPerfil (playload){
        try {
          return axios.post('/trocasenha',playload);

        } catch (e) {
          return e;
        }
    }
}
