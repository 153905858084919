<template>
  <div class="app-layout__navbar">
    <va-navbar>
      <template v-slot:left>
        <div class="left">
          <va-icon-menu-collapsed
            @click="isSidebarMinimized = !isSidebarMinimized"
            :class="{ 'x-flip': isSidebarMinimized }"
            class="va-navbar__item cursor"
            :color="colors.primary"
          />
          <!--<router-link to="/painel">
            <vuestic-logo class="logo"/>
          </router-link>    -->
          <a href="/admin/painel-adm" v-if="funcaoDoUsuario == 1 || funcaoDoUsuario == 2 || funcaoDoUsuario == 7">
            <vuestic-logo class="logo"/>
          </a>
           <a href="/ambiente-professor/turmas-professor"  v-if="funcaoDoUsuario == 5">
            <vuestic-logo class="logo"/>
          </a>
          <a href="/ambiente-coordenacao/coordenacao-escolas" v-if="funcaoDoUsuario == 3 || funcaoDoUsuario == 4">
            <vuestic-logo class="logo"/>
          </a>
        </div>
      </template>
      <template v-slot:center>
        <span class="app-navbar__text">
          <!--<div class="nav-nome-escola" v-if="nome_escola == 1">
            {{escola}}
          </div> -->

          <!--{{$t('navbar.messageUs')}}&nbsp;-->
          <!--<a
            href="mailto:hello@epicmax.co"
            target="_blank"
            :style="{color: colors.primary}"
          >
            hello@epicmax.co
          </a>
          <va-button
            href="https://github.com/epicmaxco/vuestic-admin"
            color="#000000"
            class="app-navbar__github-button"
            icon="github"
            target="_blank"
          >
            {{$t('navbar.repository')}}
          </va-button>-->

        </span>
      </template>
      <template #right>
        <h6 class="userNav" style="margin-top: 6px;font-weight: 600;">{{usuario_n}}</h6>
        <app-navbar-actions :funcaoDoUsuario="funcaoDoUsuario"
          class="app-navbar__actions md5 lg4"
          :user-name="usuario_logado"
        />
      </template>
    </va-navbar>
  </div>
</template>

<script>
import { LoginInfo } from "@/class/login";
import { useColors } from 'vuestic-ui'
import { useStore } from 'vuex'
import { computed } from 'vue'
import VuesticLogo from '@/components/vuestic-logo'
import VaIconMenuCollapsed from '@/components/icons/VaIconMenuCollapsed'
import AppNavbarActions from './components/AppNavbarActions'

export default {
  components: { VuesticLogo, AppNavbarActions, VaIconMenuCollapsed },
  props:{
    nome_escola:0
  },
  data () {
    return {
      usuario_n:"",
      usuario_logado:"",
    }
  },
  methods:{
    async usuario(){
      const token = sessionStorage.getItem("token");
      const whoiam = await LoginInfo.WhoIam(token);
      this.usuario = "";
      if(whoiam.data.funcao == 5){
        let user_nome = whoiam.data.usuario.nome;
        user_nome = user_nome.toUpperCase();
        this.usuario_n = "Olá "+user_nome;
      }else{
        this.usuario_logado = whoiam.data.usuario.nome;
      }
    }
  },
  async beforeMount() {
    this.usuario();

  },
  setup(){
    const escola = sessionStorage.getItem("escola");
    const funcaoDoUsuario = sessionStorage.getItem("funcaoDoUsuario");
    const { getColors } = useColors()
    const colors = computed(() => getColors() )
    const store = useStore()

    const isSidebarMinimized = computed({
      get: () => store.state.isSidebarMinimized,
      set: (value) => store.commit('updateSidebarCollapsedState', value)
    })

    const userName = computed(() => store.state.userName)
    return {
      colors,
      isSidebarMinimized,
      userName,
      escola: escola,
      funcaoDoUsuario:funcaoDoUsuario
    }
  }
  /*setup() {
    const escola = sessionStorage.getItem("escola");
    const funcaoDoUsuario = sessionStorage.getItem("funcaoDoUsuario");
    const { getColors } = useColors()
    const colors = computed(() => getColors() )
    const store = useStore()

    const isSidebarMinimized = computed({
      get: () => store.state.isSidebarMinimized,
      set: (value) => store.commit('updateSidebarCollapsedState', value)
    })

    const userName = computed(() => store.state.userName)
    return {
      colors,
      isSidebarMinimized,
      userName,
      escola: escola,
      funcaoDoUsuario:funcaoDoUsuario
    }
  },*/
}
</script>

<style >
  .nav-nome-escola{
    margin-top: 0px;
    color: #ff7f27;
    font-size: 25px;
    font-weight: 600;
  }

  .cursor{
    cursor:pointer;
  }
</style>

<style lang="scss" scoped>
  .va-navbar {
    box-shadow: var(--va-box-shadow);
    z-index: 2;
    &__center {
      @media screen and (max-width: 1200px) {
        .app-navbar__github-button {
          display: none;
        }
      }
      @media screen and (max-width: 950px) {
        .app-navbar__text {
          display: none;
        }
      }
    }

    @media screen and (max-width: 950px) {
      .left {
        width: 100%;
      }
      .app-navbar__actions {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .left {
    display: flex;
    align-items: center;
    & > * {
      margin-right: 1.5rem;
    }
    & > *:last-child {
      margin-right: 0;
    }
  }

  .x-flip {
    transform: scaleX(-100%);
  }

  .app-navbar__text > * {
    margin-right: 0.5rem;
    &:last-child {
      margin-right: 0;
    }
  }
</style>
