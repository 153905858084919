import axios from 'axios';
import store_token_info from "@/store/store_token_info.js";

class Util {
  async WhoIam (token){
    try {
      //console.log("O token eh esse: ", token);

      const res = await axios.get('/whoiam',{
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+token,

        headers: {
          'cache-control': 'max-age=3600',
        }
      });
      //console.log(res);

      store_token_info.usuario_logado = res.data.id;
      return res.data;
    } catch (e) {
      return e;
    }
  }
}

export default Util;
