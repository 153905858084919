import axios from "axios";

export class Calendario {
  static async listaDeSegmentosDisponiveis(payload) {
    return axios.post("segmentos/disponiveis", payload);
  }
  static async verificarExisteCalendario() {
    return axios.get("/calendario/existe");
  }
  static async calendarioAtual() {
    return axios.get("/calendario");
  }
  static async ano() {
    return axios.get("/select/ano");
  }
  static async verificarSeExistemCalendarioAFechar() {
    return axios.get("todos-calendarios-estao-fechados");
  }
  static async buscarTodosCalendarios(ano) {
    return axios.get("calendario/exibir-todos/" + ano);
  }
  static async marcarDesmarcar(payload) {
    return axios.put("/calendario/item/" + payload.desmarcar, payload);
  }
  static async cadastrar(payload) {
    return axios.post("/calendario", payload);
  }
  static async start(payload) {
    return axios.post("/calendario/start", payload);
  }
  static async fechar(id, tipo) {
    return axios.put("calendario/fechar/" + id + "/" + tipo);
  }
  static async ativarCalendario(id) {
    return axios.put("calendario/ativar/" + id);
  }
  static async desativarCalendario(id) {
    return axios.put("calendario/desativar/" + id);
  }
  static async alterar(payload) {
    return axios.put("/calendario/" + payload.calendario_letivos_id, payload);
  }
  static async remover(id) {
    return axios.delete("/calendario/" + id);
  }

  static async calendarioEditarConfig(id, payload) {
    return axios.put("calendario/editar/config/" + id, payload);
  }

  static async validarLinkarSegmentoAoCalendario(payload) {
    return axios.post(
      "validar-linkar-segmento/calendario/" +
        payload.id +
        "/" +
        payload.segmento_id
    );
  }
  static async linkarSegmentoAoCalendario(payload) {
    return axios.post("linkar-segmento/calendario/" + payload.id, payload);
  }
  static async calendarioAtual2(segmento) {
    return axios.get("calendario/" + segmento);
  }
  static async linkarVariosSegmentosAoCalendario(payload) {
    return axios.post(
      "linkar-segmento/multiplos/calendario/" + payload.calendario_letivo_id,
      payload
    );
  }
  static async linkarSegmentoAoCalendarioEditar(payload) {
    return axios.put(
      "linkar-segmento/editar/calendario/" + payload.id,
      payload
    );
  }

  static async buscarCalendario(segmento_id) {
    return axios.get("buscar-calendario-dado-segmeto/" + segmento_id);
  }

  static async excluir(id) {
    return axios.delete("calendario/" + id);
  }
}
