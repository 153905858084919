import axios from 'axios'

/* Desenvolvimento */

//axios.defaults.baseURL = 'http://localhost:8000/api/v1';
//axios.defaults.baseURL = 'http://localhost:8888/api/v1';


axios.defaults.baseURL = 'https://api-sge.cardosomoreira.rj.gov.br/api/v1';
//sfs

axios.defaults.headers.common['Authorization'] = 'Bearer ' + sessionStorage.getItem("token");

export const axiosConfig = () => {
  axios.interceptors.response.use((config) => config, async (error) => {
    if (error.response.status === 401 ) {
      location.href='/auth/login';
    }
    /*if (error.response.status === 401 || error.response.status === 500 ) {
      location.href='/auth/login';
    }*/
    return Promise.reject(error);
  })
};
