<template>
  <va-sidebar
    :width="width"
    :minimized="minimized"
    :minimizedWidth="minimizedWidth"
  >
    <menu-minimized v-if="minimized" :items="items" />
    <menu-accordion v-else :items="items" />
  </va-sidebar>
  <div v-html="c"></div>
</template>

<script>
import { LoginInfo } from "@/class/login";
import { useGlobalConfig } from 'vuestic-ui';
import MenuAccordion from './menu/MenuAccordion.vue';
import MenuMinimized from './menu/MenuMinimized.vue';
import NavigationRoutes from './NavigationRoutesProfessor';


export default {
  name: "app-sidebar",
  components: {
    MenuAccordion,
    MenuMinimized,
  },
  props: {
    width: { type: String, default: '16rem' },
    color: { type: String, default: "secondary" },
    minimized: { type: Boolean, required: true },
    minimizedWidth: {
      type: Boolean,
      required: false,
      default: undefined
    },
  },
  data() {
    return {
      //items: NavigationRoutes.routes,
      items:[],
      routes:[],
      c:'',
    };
  },
  computed: {
    computedClass() {
      return {
        "app-sidebar--minimized": this.minimized
      };
    },
    colors() {
      return useGlobalConfig().getGlobalConfig().colors
    },
  },
  methods:{
    montarRotas(permisoes){
      this.routes = [];
      let educ =[];
      educ = {
        name: 'ambiente-professor',
        displayName: 'Area de Trabalho',
        meta: {
          icon: 'vuestic-iconset-statistics',
        },
        children: []
      };

      let children = [];
      children = [];

      for (const el of permisoes) {

        if(el.modulo == 'Calendário'){
          let novo = {
            name: 'calendarioProfessor',
            displayName: 'Calendario Letivo',
          };
          children.push(novo);
        }

        if(el.modulo == 'Turmas - Professor'){
          let novo = {
            name: 'turmas-Professor',
            displayName: 'Turmas do Professor',
          };
          children.push(novo);
        }
      }

      let novo = {
        name: 'painel-professor',
        displayName: 'Painel de Gestão',
      };
      children.push(novo);

      // let relatorioProf = {
      //   name: 'relatorios-professor',
      //   displayName: 'Relatórios',
      // };
      // children.push(relatorioProf);

      /*for (const el of permisoes) {
        if(el.modulo == 'Presenca - Professor'){
          let novo = {
            name: 'presencaProfessorEditar',
            displayName: 'Visualizar Presenças',
          };
          children.push(novo);
        }
      }*/

      educ.children = children;
      this.routes.push(educ);
    }
  },
  async beforeMount() {
    const colorBar = sessionStorage.getItem("colorBar");
    const colorBar_text = sessionStorage.getItem("colorBar_text");
    if(colorBar){
      this.c ="<style>.va-sidebar{background-color: "+colorBar+" !important; color: "+colorBar_text+" !important } .app-layout__sidebar-wrapper{background-color: "+colorBar+" !important;} </style>";
    }

    const token = sessionStorage.getItem("token");
    const whoiam = await LoginInfo.WhoIam(token);
    this.montarRotas(whoiam.data.permissao);
    this.items = this.routes;
    //this.items = NavigationRoutes.routes;
  },
};
</script>

<style lang="scss">
.va-sidebar {
  .va-collapse__body {
    margin-top: 0 !important;
  }

  &__menu {
    padding: 2rem 0;
    &__inner {
      padding-bottom: 8rem;
    }
  }

  &-item {
    &-content {
      padding: 0.75rem 1rem;
    }

    &__icon {
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>

<style lang="scss" scoped>
.va-sidebar {
  flex-shrink: 0;
}

// .va-sidebar--minimized {
//   width: auto !important;
// }
</style>
