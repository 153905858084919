export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'painel',
      displayName: 'Painel Administrativo',
      meta: {
        icon: 'vuestic-iconset-dashboard',
      },
    },

    {
      name: 'calendario',
      displayName: 'Calendário Letivo',
      meta: {
        icon: 'vuestic-iconset-dashboard',
      },
    },

    {
      name: 'educacao',
      displayName: 'Educação',
      meta: {
        icon: 'vuestic-iconset-statistics',
      },
      children: [
        {
          name: 'escolas',
          displayName: 'Escolas',
        },
        {
          name: 'segmentos',
          displayName: 'Segmentos',
        },
        {
          name: 'series',
          displayName: 'Períodos',
        },
        {
          name: 'disciplinas',
          displayName: 'Disciplinas',
        },
        {
          name: 'servidores',
          displayName: 'Servidores',
        },
        /*{
          name: 'professoresall',
          displayName: 'Professores',
        },
        {
          name: 'coordenadoresall',
          displayName: 'Coordenadores',
        },
        {
          name: 'diretorall',
          displayName: 'Diretores',
        },*/
        {
          name: 'alunosAll',
          displayName: 'Alunos',
        },
        {
          name: 'turmas',
          displayName: 'Turmas',
        },
        /*{
          name: 'planejamentoPedaAll',
          displayName: 'Planejamento Pedagógico',
        },*/
        {
          name: 'presencaAll',
          displayName: 'Presença dos alunos',
        },
        {
          name: 'notasAll',
          displayName: 'Notas das Turmas',
        },
        {
          name: 'conteudo-aplicado-gestor',
          displayName: 'Conteúdo Aplicado',
        }



        // {
        //   name: 'relatorios-gestor',
        //   displayName: 'Relatórios',
        // },
      ],
    },
    {
      name: 'configuracao',
      displayName: 'Configuração',
      meta: {
          icon: 'vuestic-iconset-settings',
      },
      children: [
        {
            name: 'automatizar',
            displayName: 'Disciplinas ',
        },
        {
          name: 'logo',
          displayName: 'Logos',
        },
        {
          name: 'configuracao-sistema',
          displayName: 'Sistema',
        },
        {
          name: 'relatorios-gestor',
          displayName: 'Relatorio',
        },
        {
          name: 'permissao',
          displayName: 'Permissões',
        },
        {
          name: 'arquivos_xml',
          displayName: 'Inserir Arquivo XML',
        }
      ],
    },

    /*{
      name: 'esic',
      displayName: 'e-SIC',
      meta: {
        icon: 'vuestic-iconset-statistics',
      },
    },*/

    /*{
      name: 'convid-19',
      displayName: 'COVID-19',
      meta: {
        icon: 'vuestic-iconset-statistics',
      },
      children: [
        {
          name: 'painel-vacinacao',
          displayName: 'Painel de Vacinação',
        },
        {
          name: 'lista-vacinados',
          displayName: 'Lista de Vacinados',
        },
        {
          name: 'informativos',
          displayName: 'Informativos',
        },

        {
          name: 'dose-recebidas',
          displayName: 'Doses Recebidas',
        },
        {
          name: 'frequentes-perguntas',
          displayName: 'Perguntas Frequentes',
        },
        {
          name: 'cronograma-vacinaçao',
          displayName: 'Cronograma de Vacinação',
        },
      ],
    },*/


  /*  {
      name: 'ouvidoria',
      displayName: 'Ouvidoria',
      meta: {
        icon: 'vuestic-iconset-statistics',
      },
    },

    {
      name: 'ajuda',
      displayName: 'Ajuda',
      meta: {
        icon: 'vuestic-iconset-statistics',
      },
      children: [
        {
          name: 'sobreportal',
          displayName: 'Sobre Portal',
        },
        {
          name: 'glossario',
          displayName: 'Glossário',
        },
        {
          name: 'perguntas-frequentes',
          displayName: 'Perguntas Frequentes',
        },
      ],
    },
    {
      name: 'fale-Conosco',
      displayName: 'Fale Conosco',
      meta: {
        icon: 'vuestic-iconset-statistics',
      },
    },

    {
      name: 'governos',
      displayName: 'Governo',
      meta: {
        icon: 'vuestic-iconset-statistics',
      },
      children: [
        {
          name: 'orgaos-secretarias-organograma',
          displayName: 'Orgão e Secretarias',
        },
        {
          name: 'legislacao',
          displayName: 'Legislação',
        },
        {
          name: 'manuais',
          displayName: 'Manuais',
        },

        {
          name: 'orientacao-normativas',
          displayName: 'Orientações Normativas',
        },
        {
          name: 'carta-servico',
          displayName: 'Cartas de Serviços',
        },
      ],
    },

    {
      name: 'receitas',
      displayName: 'Receitas',
      meta: {
        icon: 'vuestic-iconset-statistics',
      },
      children: [
        {
          name: 'receitas-entidade',
          displayName: 'Receitas por Entidade',
        },
        {
          name: 'detalhamento-receita',
          displayName: 'Detalhamento',
        },
        {
          name: 'previsao-entidade',
          displayName: 'Previsão Por Entidade',
        },

        {
          name: 'extraorcamentos',
          displayName: 'Extraorçamentária',
        },
      ],
    },

    {
      name: 'despesas',
      displayName: 'Despesas',
      meta: {
        icon: 'vuestic-iconset-statistics',
      },
      children: [
        {
          name: 'despesas-funcao',
          displayName: 'Despesas por Função',
        },
        {
          name: 'detalhamento',
          displayName: 'Detalhamento',
        },
        {
          name: 'diario-passagens',
          displayName: 'Diários e Passagens',
        },
        {
          name: 'favoritos',
          displayName: 'Favoricidos',
        },
        {
          name: 'extraorcamento',
          displayName: 'Extraorçamentária',
        },
      ],
    },

    {
      name: 'licitacao-contrato',
      displayName: 'Licitações e Contratos',
      meta: {
        icon: 'vuestic-iconset-statistics',
      },
      children: [
        {
          name: 'licitacao',
          displayName: 'Licitação',
        },
        {
          name: 'contratos',
          displayName: 'Contratos',
        },
        {
          name: 'atasprecos',
          displayName: 'Atas de Registro de Preço',
        },
      ],
    },

    {
      name: 'convenios-transferencia',
      displayName: 'Convênios e Transferências',
      meta: {
        icon: 'vuestic-iconset-statistics',
      },
      children: [
        {
          name: 'convenios',
          displayName: 'Convênios Federais',
        },
        {
          name: 'repasses',
          displayName: 'Repasses FNDE',
        },
      ],
    },


    {
      name: 'concurso',
      displayName: 'Concursos',
      meta: {
        icon: 'vuestic-iconset-statistics',
      },
    },


    {
      name: 'pessoal',
      displayName: 'Pessoal',
      meta: {
        icon: 'vuestic-iconset-statistics',
      },
      children: [
        {
          name: 'painel-servidores',
          displayName: 'Painel de Servidores',
        },
        {
          name: 'quantidade-vinculos-ativos',
          displayName: 'Vinculos Ativos',
        },
        {
          name: 'quantidade-servidores-ativos',
          displayName: 'Servidores Ativos',
        },
        {
          name: 'quantidade-vinculos-inativo-pensionista',
          displayName: 'Inativos e Pensionistas',
        },
        {
          name: 'grafico-servidores-tipo-vinculo',
          displayName: 'Servidores Tipo de Vinculos',
        },
        {
          name: 'folha-pagamento',
          displayName: 'Folha de Pagamento',
        },
        {
          name: 'detalhes-cargo',
          displayName: 'Detalhes dos Cargos',
        },
      ],
    },


    {
      name: 'planejamento-orcamento',
      displayName: 'Planejamento e Orçamento',
      meta: {
        icon: 'vuestic-iconset-statistics',
      },
      children: [
        {
          name: 'planos-projetos',
          displayName: 'Planos e Projetos',
        },
        {
          name: 'prestacao-contas',
          displayName: 'Prestações de Contas',
        },
        {
          name: 'orcamento-municipal',
          displayName: 'Orçamento Municipal',
        },
        {
          name: 'PPA',
          displayName: 'PPA',
        },
        {
          name: 'LDO',
          displayName: 'LDO',
        },
        {
          name: 'LOA',
          displayName: 'LOA',
        },
      ],
    },


    {
      name: 'mapas',
      displayName: 'Mapas',
      meta: {
        icon: 'vuestic-iconset-statistics',
      },
      children: [
        {
          name: 'unidade-saude',
          displayName: 'Unidades da Saúde',
        },
        {
          name: 'unidade-assistencia-social',
          displayName: 'Unidades da Assistência Social',
        },
        {
          name: 'unidade-educacao',
          displayName: 'Unidade da Educação',
        },
        {
          name: 'secretaria-orgao',
          displayName: 'Secretarias e orgãos',
        },
      ],
    },


  /*  {
      name: 'login',
      displayName: '',
      meta: {
        icon: 'entypo entypo-adjust',
      },
    },




    {
      name: 'statistics',
      displayName: 'menu.statistics',
      meta: {
        icon: 'vuestic-iconset-statistics',
      },
      disabled: true,
      children: [
        {
          name: 'charts',
          displayName: 'menu.charts',
        },
        {
          name: 'progress-bars',
          displayName: 'menu.progressBars',
        },
      ],
    },
    {
      name: 'forms',
      displayName: 'menu.forms',
      meta: {
        icon: 'vuestic-iconset-forms',
      },
      disabled: true,
      children: [
        {
          name: 'form-elements',
          displayName: 'menu.formElements',
        },
        {
          name: 'medium-editor',
          displayName: 'menu.mediumEditor',
        },
      ],
    },
    {
      name: 'tables',
      displayName: 'menu.tables',
      meta: {
        icon: 'vuestic-iconset-tables',
      },
      children: [
        {
          name: 'markup',
          displayName: 'menu.markupTables',
        },
        // {
        //   name: 'data',
        //   displayName: 'menu.dataTables',
        // },
      ],
    },
    {
      name: 'ui',
      displayName: 'menu.uiElements',
      meta: {
        icon: 'vuestic-iconset-ui-elements',
      },
      disabled: true,
      children: [
        {
          name: 'buttons',
          displayName: 'menu.buttons',
        },
        {
          name: 'cards',
          displayName: 'menu.cards',
        },
        {
          name: 'chat',
          displayName: 'menu.chat',
        },
        {
          name: 'chips',
          displayName: 'menu.chips',
        },
        {
          name: 'collapses',
          displayName: 'menu.collapses',
        },
        {
          name: 'colors',
          displayName: 'menu.colors',
        },
        // {
        //   name: 'color-pickers',
        //   displayName: 'menu.colorPickers',
        // },
        {
          name: 'file-upload',
          displayName: 'menu.fileUpload',
        },
        {
          name: 'grid',
          displayName: 'menu.grid',
        },
        {
          name: 'icon-sets',
          displayName: 'menu.icons',
          children: [
            {
              displayName: 'concrete',
              name: 'icon-set',
            },
          ],
        },
        {
          name: 'lists',
          displayName: 'menu.lists',
        },
        {
          name: 'modals',
          displayName: 'menu.modals',
        },
        {
          name: 'notifications',
          displayName: 'menu.notifications',
        },
        {
          name: 'popovers',
          displayName: 'menu.popovers',
        },
        {
          name: 'rating',
          displayName: 'menu.rating',
        },
        {
          name: 'sliders',
          displayName: 'menu.sliders',
        },
        {
          name: 'spacing',
          displayName: 'menu.spacing',
        },
        {
          name: 'spinners',
          displayName: 'menu.spinners',
        },
        {
          name: 'tabs',
          displayName: 'menu.tabs',
        },
        {
          name: 'timelines',
          displayName: 'menu.timelines',
        },
        {
          name: 'tree-view',
          displayName: 'menu.treeView',
        },
        {
          name: 'typography',
          displayName: 'menu.typography',
        },
      ],
    },
    {
      name: 'maps',
      displayName: 'menu.maps',
      meta: {
        icon: 'vuestic-iconset-maps',
      },
      disabled: true,
      children: [
        {
          name: 'google-maps',
          displayName: 'menu.google-maps',
        },
        {
          name: 'yandex-maps',
          displayName: 'menu.yandex-maps',
        },
        {
          name: 'leaflet-maps',
          displayName: 'menu.leaflet-maps',
        },
        {
          name: 'bubble-maps',
          displayName: 'menu.bubble-maps',
        },
        {
          name: 'line-maps',
          displayName: 'menu.line-maps',
        },
      ],
    },
    {
      name: 'pages',
      displayName: 'menu.pages',
      meta: {
        icon: 'vuestic-iconset-files',
      },
      disabled: true,
      children: [
        {
          name: 'login',
          displayName: 'menu.login-singup',
        },
        {
          name: '404-pages',
          displayName: 'menu.404-pages',
        },
        {
          name: 'faq',
          displayName: 'menu.faq',
        },
      ],
    },*/
  ],
}
